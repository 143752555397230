<template>
    <div class="navbarmobileFix">
        <div>
            <section class="banner-two2 img banner">
                <div class="container-fluid text-center text-align">

                    <nav class="d-none d-lg-block three-nav1">
                        <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
                            <li class="three-nav1" @click="joinPatientGroups()">
                                <div>
                                    <img src="~@/assets/images/community.png" style="width: 40px;">
                                </div>
                            </li>
                            <li class="three-nav1" @click="accessReliableInformation()">
                                <div>
                                    <img src="~@/assets/images/bookcreative.png" style="width: 40px;">
                                </div>
                            </li>
                            <li class="three-nav1" @click="searchClinic()">
                                <div>
                                    <img src="~@/assets/images/hospitallens.png" style="width: 40px;">
                                </div>
                            </li>
                            <!-- <li class="three-nav1" @click="requestConsultation()">
                            <div>
                                <img src="~@/assets/images/hospitalplus.png" style="width: 40px;">
                            </div>
                        </li> -->
                        </ul>
                    </nav>

                    <div class="container">
                        <!-- FOR DESKTOP  -->
                        <div class="heading-community d-none d-lg-block">
                            <h1>Join doctors’ online communities</h1>
                        </div>
                        <!-- FOR MOBILE  -->
                        <div class="heading-community d-block d-lg-none">
                            <h1 class="mb-0">Join a doctor's</h1>
                            <h1>online community</h1>
                        </div>
                        <!-- FOR DESKTOP  -->
                        <div class="banner-sub-content onlinecommunityText d-none d-lg-block">
                            <h3 class="mb-0">Doctors who use RxIx can publish their knowlege and</h3>
                            <h3>wisdom to their community members. </h3>

                            <h3 class="mt-4 mb-0">Join communities and benefit from their communication.</h3>

                            <h3 class="mt-4 mb-0">This is also an opportunity for you to know about a doctor</h3>
                            <h3>before you consult him/her.</h3>

                            <h3 class="mt-4">There are no joining fees or annual membership charges.</h3>
                        </div>
                        <!-- FOR MOBILE  -->
                        <div class="banner-sub-content onlinecommunityText d-block d-lg-none">
                            <h3 class="mb-0">Doctors who use RxIx can</h3>
                            <h3 class="mb-0">publish their knowlege and</h3>
                            <h3 class="mb-0">wisdom to their community </h3>
                            <h3>members.</h3>


                            <h3 class="mt-4 mb-0">Join communities and</h3>
                            <h3 class="mb-0">benefit from their</h3>
                            <h3>communication.</h3>


                            <h3 class="mt-4 mb-0">This is also an opportunity for</h3>
                            <h3 class="mb-0">you to know about a doctor</h3>
                            <h3>before you consult him/her.</h3>


                            <h3 class="mt-4 mb-0">There are no joining fees or </h3>
                            <h3>annual membership charges.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <!-- FOR DESKTOP  -->
            <section class="banner-two2 img-bottom mb-5">
                <div class="container text-center text-align">
                    <div class="selectdoctor d-none d-lg-block">
                        <h2>Select a doctor's community</h2>
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="selectdoctor d-block d-lg-none">
                        <h2 class="mb-0">Select a doctor's</h2>
                        <h2>community</h2>
                    </div>
                    <div class="row box-items-space">
                        <div class="col-opt-box" v-for="(user, index) in usersHistory" :key="index" @click="signupgeneral(user)">
                            <div class="col-fix mt-3 color" >
                                <div class="btn-text">
                                    <div class="box-text">{{ user.firstName }} {{ user.lastName }}</div>
                                    <div v-if="user.practiceIdString">
                                        <p class="subpracticeareaText">{{ user.practiceIdString }}
                                        </p>
                                        <!-- <span v-if="index === 3">{{ '...' }}</span> -->
                                    </div>
                                </div>

                            </div>
                            <div>
                                <button class="btn btn-actionDoctorGrourps">Join community</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <MobileNavbar></MobileNavbar>
    </div>
</template>

<script>
import MobileNavbar from "../common-mobile-footer.vue"
import { defineComponent } from "vue";
import axios from "axios";
export default defineComponent({
    components: {
        MobileNavbar

    },
    el: '#hide',
    data() {
        return {
            seen: true,
            currentPath: "",
            usersHistory: [],
            practiceId: '',
        };
    },

    created: function () {
        this.currentPath = this.$route.path.split('/')[2]
        this.getUserHistory();
    },
    methods: {
        async signupgeneral(queryParams) {
            const doctorCommunityPayload={
                doctorCommunityData: JSON.stringify(queryParams),
                doctorCommunityId: JSON.stringify(2),
                card:'public',
                routeName : 'joindoctorscommunity',
            }
            
            this.$router.push({ path: '/signup', query: doctorCommunityPayload });
        },
        joinPatientGroups() {
      window.location.href = "/public/patientgroups"; 
        },
        accessReliableInformation() {
      window.location.href = "/public/patientresources"; 
        },
        requestConsultation() {
      window.location.href = "/public/requestconsultation"; 
    },
    searchClinic() {
      window.location.href = "/public/searchclinic"; 
    },
        async getUserHistory() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
            await axios
                .get(`${BASE_API_URL}/hcps`)
                .then((response) => {
                    if (response?.data) {
                        response?.data?.forEach((data) => {
                            if (data?.status?.status === 'Approved' && data.mobile != '9182701237') {
                                if (data.practiceId) {
                                    const practiceAreaNames = data.practiceId.map(item => item.practiceArea);
                                    if (practiceAreaNames.length > 3) {
                                        data.practiceIdString = practiceAreaNames.slice(0, 3).join(", ") + " ...";
                                    } else {
                                        data.practiceIdString = practiceAreaNames.join(", ");
                                    }
                                }
                                this.usersHistory.push(data);
                            }
                        });
                    }
                });
        },
    }
})

</script>

<style>
p.subpracticeareaText {
    font-size: 17px;
    margin-bottom: 0px;
}

nav.three-nav1 {
    float: right;
    position: fixed;
    right: -3px;
    background: transparent;
}

nav.three-nav1:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width:992px) {
    nav.three-nav1 ul.three-nav1 {
        text-align: center;
        margin-top: 0px
    }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
    line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
    opacity: 1;
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav1 ul.three-nav1 li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
    list-style: none;
    position: relative;
    left: 35px;
    bottom: 20px;
}

div.box-text {
    font-size: 22px;
    color: #00979e;
}


.heading-community {
    margin-top: 20px;
}

.heading-community h1 {
    color: #000000;
    text-align: center;
    font-weight: bold;
}

.banner-sub-content {
    margin-top: 20px;
}


.icon-curve3 {
    width: 100%;
    opacity: 0.5
}

.selectdoctor {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.selectdoctor h2 {
    color: #000;
    text-align: center;
}

.row.box-items-space {
    margin-top: 40px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 22% 22% 22%;
    justify-content: center;
}
.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
    cursor: pointer;
}

.col-fix.mt-3.color {
    flex-direction: column;
    border: 2px solid #00979e;
    width: 242px;
    height: auto;
    min-height: 160px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}



.btn-text {
    text-align: center;
    margin: 10px;
    padding: 10px;
    /* color: #00979e; */
}



button.btn.btn-actionDoctorGrourps {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
    font-size: 17px;
}
.btn-actionDoctorGrourps:hover {
    background: #00979e;
    color: #fff;
}

section.banner-two2.img-bottom {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
}

section.banner-two2.img.banner {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    padding: 30px 0px 50px 0px;
    /* transform: rotate(180deg);  */
}

.navbar-light .navbar-nav .nav-link {
    color: #00979e;
}

@media screen and (max-width:1400px) {
    .row.box-items-space {
        grid-gap: 75px
    }
}

@media screen and (max-width:992px) {
    .btn-text {
        text-align: center;
        margin: 0px;
        padding: 2px;
        /* color: #00979e; */
    }

    .col-fix.mt-3.color {
        width: 206px;
        min-height: 143px;
    }
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    section.banner-two2.img.banner {
        margin-top: 0px;
    }

    .heading-community h1 {
        font-size: 33px;
    }

    .onlinecommunityText h3 {
        font-size: 22px;
    }

    .selectdoctor h2 {
        font-size: 28px;

    }

    .selectdoctor {
        margin-top: 2rem;
    }

}

@media screen and (max-width:768px) {
    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
    }
}

@media screen and (max-width: 620px) {
    .col-fix.mt-3.color {
        width: 190px;
        min-height: 145px;
    }

    div.box-text {
        font-size: 20px;
    }

    p.subpracticeareaText {
        font-size: 15px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width:576px) {

    .heading-community h1 {
        font-size: 25px;
    }

    .onlinecommunityText h3 {
        font-size: 17px
    }

    .selectdoctor h2 {
        font-size: 21px;
    }

}

@media screen and (max-width: 560px) {

    section.banner-two2.img.banner {
        padding: 10px 0px 20px 0px;
    }

    .selectdoctor {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width:550px) {
    .row.box-items-space {
        margin-top: 10px;
    }
}

@media screen and (max-width:470px) {

    .col-fix.mt-3.color {
        width: 133px;
        min-height: 105px;
    }

    p.subpracticeareaText {
        font-size: 12px;
        margin-bottom: 0px;
    }

    button.btn.btn-actionDoctorGrourps {
        font-size: 14px;
    }

    div.box-text {
        font-size: 15px;
    }

    .btn-text {
        padding: 9px;
    }
}
</style>
